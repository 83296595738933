import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f6f6f6;
  border-left: 1px solid #eeeeee;
  /* border: 1px solid #b88923; */
  .left {
    height: 100%;
    min-height: 190px;
    border-right: 1px solid #b88923;
    padding: 40px 25px;
    padding-bottom: 20px;
  }
  .right {
    padding: 40px 25px;
    .input {
      height: 50px;
      width: 100%;
      border: 1px solid #b88923;
    }
    .title {
      color: #b17d2f;
      font-size: 14px;
    }
    .textarea {
      height: 150px;
      width: 100%;
      border: 1px solid #b88923;
    }
    .lebel {
      color: #8c5a04;
      font-size: 15px;
    }
    .flex {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;

      button {
        width: 48%;

        height: 50px;
      }
      .outline {
        border: 1px solid #b88923;
        background-color: transparent;
      }
      .fill {
        background-color: #a26508;
        border: none;
        color: #fffbbf;
      }
    }
  }
`;

export const Review = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 45px 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
  .top {
    display: flex;
    justify-content: space-around;
    p {
      font-size: 13px;
      font-weight: 100;
      color: #8c5a04;
    }
  }
  .review {
    font-size: 13px;
    margin: 0;
    color: #5d5c56;
    font-weight: 100;
  }
`;
