import { Container } from "./styles";
import { useState } from "react";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
const CustomAccordion = () => {
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<Container>
			<h1>ACCORDION WIDGET</h1>
			<div>
				<Accordion
					expanded={expanded === "panel1"}
					onChange={handleChange("panel1")}
					className={`${expanded === "panel1" && "holder"} margin`}
				>
					<AccordionSummary
						expandIcon={
							expanded === "panel1" ? (
								<AiOutlineMinusCircle />
							) : (
								<AiOutlinePlusCircle />
							)
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
						className='header'
					>
						<Typography className='sub' sx={{ width: "100%", flexShrink: 0 }}>
							Our Vision
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<p>
							This text is here as a place holder please do not read it. If you
							are still reading you are wasting your time please stop. So you
							have nothing to do, read away then i dought any one will stop you
							at this point.
						</p>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === "panel2"}
					onChange={handleChange("panel2")}
					className={`${expanded === "panel2" && "holder"} margin`}
				>
					<AccordionSummary
						expandIcon={
							expanded === "panel2" ? (
								<AiOutlineMinusCircle />
							) : (
								<AiOutlinePlusCircle />
							)
						}
						aria-controls='panel2bh-content'
						id='panel2bh-header'
						className='header'
					>
						<Typography className='sub' sx={{ width: "100%", flexShrink: 0 }}>
							Our Mission
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<p>
							This text is here as a place holder please do not read it. If you
							are still reading you are wasting your time please stop. So you
							have nothing to do, read away then i dought any one will stop you
							at this point.
						</p>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === "panel3"}
					onChange={handleChange("panel3")}
					className={`${expanded === "panel3" && "holder"} margin`}
				>
					<AccordionSummary
						expandIcon={
							expanded === "panel3" ? (
								<AiOutlineMinusCircle />
							) : (
								<AiOutlinePlusCircle />
							)
						}
						aria-controls='panel3bh-content'
						id='panel3bh-header'
						className='header'
					>
						<Typography className='sub' sx={{ width: "100%", flexShrink: 0 }}>
							Our Values
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<p>
							This text is here as a place holder please do not read it. If you
							are still reading you are wasting your time please stop. So you
							have nothing to do, read away then i dought any one will stop you
							at this point.
						</p>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === "panel4"}
					onChange={handleChange("panel4")}
					className={`${expanded === "panel4" && "holder"} margin`}
				>
					<AccordionSummary
						expandIcon={
							expanded === "panel4" ? (
								<AiOutlineMinusCircle />
							) : (
								<AiOutlinePlusCircle />
							)
						}
						aria-controls='panel4bh-content'
						id='panel4bh-header'
						className='header'
					>
						<Typography
							className='sub'
							sx={{
								width: "100%",
								flexShrink: 0,
							}}
						>
							About Us
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<p>
							This text is here as a place holder please do not read it. If you
							are still reading you are wasting your time please stop. So you
							have nothing to do, read away then i dought any one will stop you
							at this point.
						</p>
					</AccordionDetails>
				</Accordion>
			</div>
		</Container>
	);
};

export default CustomAccordion;
