import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0px 0px;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  border-bottom: 1px solid #8c5a04;
  padding-bottom: 50px;
`;
