import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Container } from "./styles";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Customers = () => {
	const options = {
		items: 1,
		margin: 0,
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: false,
		arrows: true,
		// margin: 0,
	};

	const items = [
		<div key={1} className='carousel-item'>
			<p></p>
			<div className='content'>
				<p>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop.
				</p>
			</div>
		</div>,

		<div key={2} className='carousel-item'>
			<p></p>
			<div className='content'>
				<p>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop.
				</p>
			</div>
		</div>,
	];

	return (
		<Container>
			<h1>OUR HAPPY CUSTOMERS</h1>
			<div className='slider'>
				<OwlCarousel {...options} className='owl-theme '>
					{items.map((item) => (
						<div key={item.key}>{item}</div>
					))}
				</OwlCarousel>
				<p className='piramid'></p>
			</div>
			<div>
				<p className='name'>DANIEL FISSIHA</p>
				<p className='sub-title'>
					{" "}
					<span style={{ color: "#000" }}>CEO of company AS </span> Design Group
				</p>
			</div>
		</Container>
	);
};

export default Customers;
