import { Container } from "./styles";

const Login = () => {
	return (
		<Container>
			<h1>Login Area</h1>

			<input type='text' placeholder='User Name' />
			<input type='password' placeholder='Password' />
			<button>Login</button>
		</Container>
	);
};

export default Login;
