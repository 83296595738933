import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Container } from "./styles";
import qoute from "../../../../../Assets/testtimonial-qoute.png";
import avatar from "../../../../../Assets/Daniel.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonials = () => {
  const options = {
    items: 3,
    margin: 10,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: false,
    arrows: true,
    // margin: 0,
  };

  const items = [
    <div key={1} className="carousel-item">
      <img src={qoute} alt="qoute" className="qoute" />
      <div className="content">
        <p className="description">
          This text is here as a place holder please do not read it. If you are
          still reading you are wasting your time please stop. So you have
          nothing to do, read away then
        </p>
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Samrawite Abebe</p>
        <p className="title">some one</p>
      </div>
    </div>,
    <div key={2} className="carousel-item">
      <img src={qoute} alt="qoute" className="qoute" />
      <div className="content">
        <p className="description">
          This text is here as a place holder please do not read it. If you are
          still reading you are wasting your time please stop. So you have
          nothing to do, read away then
        </p>
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Samrawite Abebe</p>
        <p className="title">some one</p>
      </div>
    </div>,
    <div key={3} className="carousel-item">
      <img src={qoute} alt="qoute" className="qoute" />
      <div className="content">
        <p className="description">
          This text is here as a place holder please do not read it. If you are
          still reading you are wasting your time please stop. So you have
          nothing to do, read away then
        </p>
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Samrawite Abebe</p>
        <p className="title">some one</p>
      </div>
    </div>,
    <div key={4} className="carousel-item">
      <img src={qoute} alt="qoute" className="qoute" />
      <div className="content">
        <p className="description">
          This text is here as a place holder please do not read it. If you are
          still reading you are wasting your time please stop. So you have
          nothing to do, read away then
        </p>
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Samrawite Abebe</p>
        <p className="title">some one</p>
      </div>
    </div>,
  ];

  return (
    <Container>
      <h1 className="header">Client’s Testimonials</h1>
      <p className="sub">
        We understand the importance of approaching each work integrally and
        believe in the power of simple and easy communication.
      </p>
      <div className="slider">
        <OwlCarousel {...options} className="owl-theme ">
          {items.map((item) => (
            <div key={item.key}>{item}</div>
          ))}
        </OwlCarousel>
        <p className="piramid"></p>
      </div>
    </Container>
  );
};

export default Testimonials;
