import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;
	border: 1px solid #ffe4aa;
	background-color: #fff;
	padding: 30px 20px;
	margin-top: 40px;
	padding-bottom: 20px;
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}

	button {
		background-color: #f8f7f7;
		padding: 7px 10px;
		border: none;
		border-radius: 3px;
		margin: 3px;
		border: 1px solid #e9e8e8;
		cursor: pointer;
	}
`;
