import { BsQuestionSquare } from "react-icons/bs";
import {
	FaGooglePlusG,
	FaLinkedinIn,
	FaPinterestP,
	FaTwitter,
	FaVimeoSquare,
	FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Contaniner } from "./infobar.styles";

import { HiOutlineRss } from "react-icons/hi";
import { GrFacebookOption } from "react-icons/gr";
const InfoBar = () => {
	return (
		<Contaniner>
			<div className='left'>
				<Link to='tel:+49 160 9150 1935' className='right-border'>
					<BsQuestionSquare className='icon' />
					+49 160 9150 1935
				</Link>

				<Link to='mailto:adey@adey-meselesh.de'>
					<BsQuestionSquare className='icon' /> adey@adey-meselesh.de
				</Link>
			</div>
			<div className='center'>
				<Link to='#' className='right-border'>
					Account
				</Link>
				<Link to='#' className='right-border'>
					Wishlist
				</Link>
				<Link to='#' className='right-border'>
					Checkout
				</Link>
				<Link to='#'>Login</Link>
			</div>
			<div className='right'>
				<Link to='#' className='right-bottom'>
					<GrFacebookOption className='social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaGooglePlusG className='social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaLinkedinIn className=' social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaPinterestP className=' social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaTwitter className=' social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaVimeoSquare className=' social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaYoutube className='social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<HiOutlineRss className='social-logos' />
				</Link>
			</div>
		</Contaniner>
	);
};

export default InfoBar;
