import styled from "styled-components";
export const Container = styled.div`
	height: 330px;
	padding: 30px 7vw;
	margin-top: 0px;

	.inside {
		border-radius: 5px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		background-color: #b88923;
		padding: 0;
	}
	.location {
		padding: 50px 0px 20px 20px;
		padding-left: 30px;
	}
	.right_border {
		border-right: 2px solid #673a04;
		padding-right: 20px;
	}
	.header {
		color: #673a04;
		font-size: 18px;
	}
	.address {
		color: #fff;
		font-size: 13px;
	}
	.note {
		background-color: #673a04;
		padding: 15px 15px 20px 20px;
		border-radius: 0 5px 5px 0px;
	}
	.head {
		color: #fff;
		font-size: 24px;
		font-weight: 400;
	}
	.description {
		color: #fefab8;
		font-size: 13px;
	}
	.contact {
		margin: 0 auto;
		border: none;
		background-color: #b88923;
		padding: 10px 30px;
		color: #fff;
		margin-top: 10px;
	}
`;
