import styled from "styled-components";
export const Container = styled.div`
	width: 100vw;
	height: fit-content;
	padding: 120px 25vw;
	margin-top: 10px;
	background: url(${(props) => props.background}) left;
	background-position: center;
	background-size: cover;
	height: 80vh;
	margin-left: -40px !important;
	.header {
		font-size: 44px;
		color: #fff;
		font-weight: 500;
		margin: 10px 0;
	}
	.product-title {
		color: #fbc100;
		font-size: 30px;
		margin: 0;
		font-weight: 400;
		margin: 7px 0;
	}
	.discount {
		color: #fff;
		margin: 0;
		font-size: 25px;
		span {
			font-size: 42px;
			margin: 0;
			color: #fff;
		}
	}
	.description {
		width: 75%;
		color: #fff;
		font-size: 17px;
		font-weight: 200;
		line-height: 32px;
	}
	.count-down {
		display: flex;
		justify-content: space-between;
		width: 74%;

		.box {
			width: 80px;
			height: 80px;
			padding: 20px 0;
			text-align: center;
			color: #444444;
			background-color: #fefab8;
			border-radius: 10px;
			font-weight: 600;
		}
		.shop {
			height: 50px;
			background-color: #b88923;
			margin-top: 35px;
			border: none;
			color: #fff;
			font-size: 13px;
			font-weight: 200;
			padding: 0 20px;
			cursor: pointer;
		}
	}
`;
