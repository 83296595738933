// import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { BsQuestionSquare } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import carousel1 from "../../../../Assets/detail-banner.png";
import qoute from "../../../../Assets/qoute.png";
import separator from "../../../../Assets/separator.png";
import { Container } from "./ProductDetail.styles";
const ProductDetailCarousel = () => {
  const options = {
    items: 1,
    margin: 0,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    arrows: true,
    // margin: 0,
  };

  const items = [
    <div
      key={1}
      style={{
        backgroundImage: `url(${carousel1})`,
        backgroundSize: "100% 100%",
        objectFit: "cover",
        backgroundPosition: "bottom right",
        backgroundRepeat: "no-repeat",
      }}
      className="carousel-item"
    >
      <p></p>
      <div className="content">
        <img src={qoute} alt="qoute" className="qoute" />
        <p>
          By purchasing traditional cloth from our online shop, you are
          supporting weavers and helping to preserve tradition.
        </p>
        <div className="links">
          <Link to="#">
            Shop Now <BsQuestionSquare className="icon" />
          </Link>{" "}
          /{" "}
          <Link to="#">
            View Collections <BsQuestionSquare className="icon" />
          </Link>
        </div>
      </div>
    </div>,

    <div
      key={2}
      style={{
        backgroundImage: `url(${carousel1})`,
        backgroundSize: "100% 100%",
        objectFit: "cover",
        backgroundPosition: "bottom right",
        backgroundRepeat: "no-repeat",
      }}
      className="carousel-item"
    >
      <p></p>
      <div className="content">
        <img src={qoute} alt="qoute" className="qoute" />
        <p>
          By purchasing traditional cloth from our online shop, you are
          supporting weavers and helping to preserve tradition.
        </p>
        <div className="links">
          <Link to="#">
            Shop Now <BsQuestionSquare className="icon" />
          </Link>{" "}
          /{" "}
          <Link to="#">
            View Collections <BsQuestionSquare className="icon" />
          </Link>
        </div>
      </div>
    </div>,
  ];

  return (
    <Container>
      <OwlCarousel {...options} className="owl-theme">
        {items.map((item) => (
          <div key={item.key}>{item}</div>
        ))}
      </OwlCarousel>
      <img src={separator} alt="" className="separator" />
    </Container>
  );
};

export default ProductDetailCarousel;
