import React from "react";
import { Container } from "./styles";

const Subscriptions = () => {
  return (
    <Container>
      <div className="left">
        <p className="sub">
          The Most Fascinating <br /> Projects{" "}
          <span>“Don’t miss your chance”</span>
        </p>
        <p className="desc">
          This text is here as a place holder please do not read it. If you are
          still reading you are wasting your time please stop. So you have
          nothing to do, read away then i dought any one will stop you at this
          point. This text is here as a place holder please do not read it. If
          you are still reading you are wasting your time please stop. So you
          have nothing to do, read away then i dought any one will stop you at
          this point.{" "}
        </p>
        <button className="read-more">MORE READ</button>
      </div>
      <div className="right">
        <div className="card-left">
          <h1 className="price">$105</h1>
          <p className="package-name">Gold Package</p>
          <p className="duration">PER MONTH</p>
          <ul>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
          </ul>
          <button className="buy">BUY NOW</button>
        </div>
        <div className="card-center">
          <h1 className="price">$105</h1>
          <p className="package-name">Gold Package</p>
          <p className="duration">PER MONTH</p>
          <ul>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
          </ul>
          <button className="buy">BUY NOW</button>
        </div>
        <div className="card-right">
          <h1 className="price">$105</h1>
          <p className="package-name">Gold Package</p>
          <p className="duration">PER MONTH</p>
          <ul>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
            <li>
              Bene<span>fit for Cl</span>int
            </li>
          </ul>
          <button className="buy">BUY NOW</button>
        </div>
      </div>
    </Container>
  );
};

export default Subscriptions;
