import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import separator from "../../Assets/separator.png";
import { Contaniner } from "./styled";
import background from "../../Assets/footer-background.png";
const Footer = () => {
  return (
    <Contaniner background={background}>
      <img src={separator} alt="" className="separator-top" />
      <Link to="/" className="logo_container">
        <img src={logo} alt="" className="logo" />
        <span>ባህል፣ እሴት፣ ትውፊት</span>
        <span>ADEY MAHETEME</span>
      </Link>
      <div className="colum">
        <div className="left">
          <h4 className="head">Quick Links</h4>
          <Link> CATRGORIES</Link>
          <Link>ABOUT THEME</Link>
          <Link>OUR BLOG</Link>
          <Link>TRENDY ITEM</Link>
          <Link> SHOP</Link>
          <Link>CONTACT US</Link>
        </div>
        <h1 className="desc">LINKS</h1>
      </div>
      <div className="colum">
        <div className="left">
          <h4 className="head">Shopping Deals</h4>
          <Link> FASHION</Link>
          <Link> PRODUCTS</Link>
          <Link> DEALS</Link>
          <Link>CATEGORIES</Link>
          <Link> LADIES BAG</Link>
          <Link>ACCESSROIES</Link>
        </div>
        <h1 className="desc">DEALS</h1>
      </div>{" "}
      <div className="colum">
        <div className="left">
          <h4 className="head">Customers Services</h4>
          <Link> FAQ</Link>
          <Link>REFUND / RETURN POLICY</Link>
          <Link>CONTACT SUPPORT</Link>
          <Link>REPORT ABUSE</Link>
        </div>
        <h1 className="desc">SERVICES</h1>
      </div>
      <img src={separator} alt="" className="separator" />
    </Contaniner>
  );
};

export default Footer;
