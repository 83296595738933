import styled from "styled-components";

export const Container = styled.div`
  width: 100% !important;

  margin: 0;
  padding: 0;
  .banner {
    width: 100%;
  }
  .images {
    display: flex;
    height: 98px;
    max-width: 100%;
    overflow-x: scroll;
    img {
      height: 100%;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
`;
