import styled from "styled-components";
export const Container = styled.div`
	width: 100%;
	height: fit-content;
	padding: 0px 0px;
	margin: 0;
	.collections {
		width: 100%;
		height: fit-content;
		padding: 0px 0px;
		display: flex;
	}
	.left {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0;
		width: 66.66%;
		height: fit-content;
	}
	.right {
		width: 33.3%;
		height: fit-content;
	}
	.sparator {
		max-width: 100%;
		margin: 0;
		padding: 0;
		height: 20px;
		object-fit: cover;
	}
`;

export const Card = styled.div`
	background: url(${(props) => props.back}) center;
	height: ${(props) => (props.fullHeight ? "600px" : "300px")};
	background-size: 100% 100%;
	background-size: cover;

	padding-top: ${(props) => (props.fullHeight ? "570px" : "270px")};

	.title {
		display: flex;
		justify-content: space-between;
		height: 35px;
		font-size: 15px;
		font-weight: 200;
		color: #fff;
		padding: 8px 10px;
		background-color: rgba(74, 54, 8, 0.7);
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
`;
