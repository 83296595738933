import { Container } from "./styles";

const Subscribe = () => {
	return (
		<Container>
			<h1>NEWSLETTER SIGNUP</h1>

			<input type='text' placeholder='Email' />

			<button>Subscribe</button>
		</Container>
	);
};

export default Subscribe;
