import styled from "styled-components";

export const Contaniner = styled.div`
  width: 100vw;
  height: 400px;
  background: url(${(props) => props.background}) center;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  grid-gap: 25px;
  padding: 60px 10vw;

  position: relative;
  .separator {
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 100%;
  }
  .separator-top {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
  }
  .logo {
    width: 130px;
  }
  .logo_container {
    display: flex;
    flex-direction: column;

    span {
      color: #fefab8;
      font-size: 12px;
      width: 200px;
    }
  }
  .colum {
    display: grid;
    grid-template-columns: 1fr 100px;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .head {
    color: #fff;
  }
  a {
    color: #d5d2d2;
    font-size: 14px;
  }
  .desc {
    color: #b88923;
    transform: rotate(90deg);
    margin: 0;
    opacity: 0.2;
    height: fit-content;
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 100px;
    font-size: 50px;
  }
`;
