// import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container } from "./homecarousel.styles";
import carousel1 from "../../../../Assets/carousel-img.png";
import qoute from "../../../../Assets/qoute.png";
import separator from "../../../../Assets/separator.png";
import { Link } from "react-router-dom";
import { BsQuestionSquare } from "react-icons/bs";
const HomeCarousel = () => {
	const options = {
		items: 1,
		margin: 0,
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: true,
		arrows: true,
		// margin: 0,
	};

	const items = [
		<div
			key={1}
			style={{
				background: `url(${carousel1})`,
			}}
			className='carousel-item'
		>
			<p></p>
			<div className='content'>
				<img src={qoute} alt='qoute' className='qoute' />
				<p>
					Introducing our new collection of autistic traditional Ethiopian and
					Eritrean cloting. Our collection features a variety of beautiful and
					unique habesha kemis, crafted with precision and care to bring out the
					beauty of the culture of Ethiopia and Eritrea. From stunning evening
					gowns to everyday wear, we have something for everyone. Our dresses
					are perfect for special occasions, festivals, and traditional
					ceremonies. With intricate details, vibrant colors, and luxuriously
					soft fabrics, our collection of Ethiopian and Eritrean dresses is sure
					to make you stand out in any crowd. Shop now and experience the beauty
					of cultural clothing from Ethiopia.
				</p>
				<div className='links'>
					<Link to='#'>
						Shop Now <BsQuestionSquare className='icon' />
					</Link>{" "}
					/{" "}
					<Link to='#'>
						View Collections <BsQuestionSquare className='icon' />
					</Link>
				</div>
			</div>
		</div>,

		<div
			key={1}
			style={{
				background: `url(${carousel1})`,
			}}
			className='carousel-item'
		>
			<p></p>
			<div className='content'>
				<img src={qoute} alt='qoute' className='qoute' />
				<p>
					Introducing our new collection of autistic traditional Ethiopian and
					Eritrean cloting. Our collection features a variety of beautiful and
					unique habesha kemis, crafted with precision and care to bring out the
					beauty of the culture of Ethiopia and Eritrea. From stunning evening
					gowns to everyday wear, we have something for everyone. Our dresses
					are perfect for special occasions, festivals, and traditional
					ceremonies. With intricate details, vibrant colors, and luxuriously
					soft fabrics, our collection of Ethiopian and Eritrean dresses is sure
					to make you stand out in any crowd. Shop now and experience the beauty
					of cultural clothing from Ethiopia.
				</p>
				<div className='links'>
					<Link to='#'>
						Shop Now <BsQuestionSquare className='icon' />
					</Link>{" "}
					/{" "}
					<Link to='#'>
						View Collections <BsQuestionSquare className='icon' />
					</Link>
				</div>
			</div>
		</div>,
	];

	return (
		<Container>
			<OwlCarousel {...options} className='owl-theme'>
				{items.map((item) => (
					<div key={item.key}>{item}</div>
				))}
			</OwlCarousel>
			<img src={separator} alt='' className='separator' />
		</Container>
	);
};

export default HomeCarousel;
