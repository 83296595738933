import { Checkbox } from "@mui/material";
import React from "react";
import { Container } from "./styles";
const Tags = () => {
	return (
		<Container>
			<h1>Tags</h1>
			<button className='tag'>Modern</button>
			<button className='tag'>Corporate</button>
			<button className='tag'>Creative</button>
			<button className='tag'>Business</button>
			<button className='tag'>Fresh</button>
			<button className='tag'>Awsome</button>
			<button className='tag'>Ori SHop</button>
		</Container>
	);
};

export default Tags;
