import React from "react";
import { Container } from "./styles";
const Comments = () => {
	return (
		<Container>
			<h1 style={{ marginBottom: "25px" }}>RECENT COMMENTS</h1>
			<div className='comment'>
				<div className='top'>
					<h1 className='name'>SAMIEL NEGASH</h1>
					<p className='time'>5 minutes ago</p>
				</div>
				<p className='content'>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop. So you have
					nothing to do, read away then i dought any one will stop you at this
					point.{" "}
				</p>
			</div>

			<div className='comment'>
				<div className='top'>
					<h1 className='name'>SAMIEL NEGASH</h1>
					<p className='time'>10 minutes ago</p>
				</div>
				<p className='content'>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop. So you have
					nothing to do, read away then i dought any one will stop you at this
					point.{" "}
				</p>
			</div>
		</Container>
	);
};

export default Comments;
