import React from "react";
import { Container } from "./styles";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import news from "../../../../../Assets/news1.png";
import news2 from "../../../../../Assets/news2.png";
import news3 from "../../../../../Assets/news3.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link } from "react-router-dom";
import { BsQuestionSquare } from "react-icons/bs";
const News = () => {
	const options = {
		items: 3,
		margin: 50,
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: true,
		arrows: true,
		// margin: 0,
	};

	const items = [
		<div key={1} className='carousel-item'>
			<div className='content'>
				<img src={news} alt='qoute' className='qoute' />
				<p className='date'>June 4, 2015</p>
				<h4 className='news-headline'>Children Welfare Program Support </h4>
				<p className='news-body'>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop. So you have
					nothing to do, read away then i dought any one will stop you at this
					point.
				</p>
				<div className='links'>
					<Link to='#'>
						Read More <BsQuestionSquare className='icon' />
					</Link>{" "}
					<p className='author'>
						by <span> SAMIEL NEGASH </span> / 2 Weeks Ago
					</p>
				</div>
			</div>
		</div>,

		<div key={1} className='carousel-item'>
			<div className='content'>
				<img src={news2} alt='qoute' className='qoute' />
				<p className='date'>June 4, 2015</p>
				<h4 className='news-headline'>Children Welfare Program Support </h4>
				<p className='news-body'>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop. So you have
					nothing to do, read away then i dought any one will stop you at this
					point.
				</p>
				<div className='links'>
					<Link to='#'>
						Read More <BsQuestionSquare className='icon' />
					</Link>{" "}
					<p className='author'>
						by <span> SAMIEL NEGASH </span> / 2 Weeks Ago
					</p>
				</div>
			</div>
		</div>,
		<div key={1} className='carousel-item'>
			<div className='content'>
				<img src={news3} alt='qoute' className='qoute' />
				<p className='date'>June 4, 2015</p>
				<h4 className='news-headline'>Children Welfare Program Support </h4>
				<p className='news-body'>
					This text is here as a place holder please do not read it. If you are
					still reading you are wasting your time please stop. So you have
					nothing to do, read away then i dought any one will stop you at this
					point.
				</p>
				<div className='links'>
					<Link to='#'>
						Read More <BsQuestionSquare className='icon' />
					</Link>{" "}
					<p className='author'>
						by <span> SAMIEL NEGASH </span> / 2 Weeks Ago
					</p>
				</div>
			</div>
		</div>,
	];
	return (
		<Container>
			<p className='sub'>Want To Know About Us</p>
			<h1 className='header'>
				Our LAT<span>ES</span>T News
			</h1>
			<OwlCarousel {...options} className='owl-theme'>
				{items.map((item) => (
					<div key={item.key}>{item}</div>
				))}
			</OwlCarousel>
		</Container>
	);
};

export default News;
