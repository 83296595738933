import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .description {
    color: #6a4800;
    font-size: 16px;
    font-weight: 100;
    line-height: 26px;
  }
  .bottom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .right {
    position: relative;
    .avatar {
      position: absolute;
      width: 110%;
      left: -10%;
      height: 125%;
      top: 0%;
    }
  }
`;
