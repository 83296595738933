import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  height: fit-content;

  padding: 0px 0px;
  margin-top: 90px;
  padding-bottom: 0px;
  .slider {
    max-width: 67vw;
    width: 100%;
    margin-top: 50px;
  }
  .sub {
    color: #8c5a04;
    font-size: 17px;
    font-weight: 200;
    text-align: center;
    width: 570px;
    margin: 0 auto;
  }
  .header {
    color: #a26508;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }
  .carousel-item {
    background-color: #a26508;
    height: 300px;
    width: 300px;
    padding: 30px;
    :hover {
      background-color: #673a04;
    }
    .qoute {
      width: 30px;
      margin: auto;
    }
    .description {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      text-align: justify;
      text-align-last: center;
      margin: 25px auto;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border: 2px solid white;
      border-radius: 900px;
      margin: 0 auto;
    }
    .name {
      width: 100%;
      color: #fffbbf;
      font-size: 15px;
      font-size: 500;
      margin: 15px auto;
      margin-bottom: 5px;
      text-align: center;
    }
    .title {
      width: 100%;
      color: #fffbbf;
      font-size: 15px;
      font-size: 500;
      text-align: center;
      margin: 0;
    }
  }
  .owl-theme .owl-dots .owl-dot span {
    background: #916404 !important;
    width: 15px !important;
    height: 15px !important;
    border: 2px solid #d3c19b;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #673a04 !important;
    border: none;
  }
`;
