import styled from "styled-components";

export const Contaniner = styled.div`
	width: 100vw;
	height: 75px;
	background: linear-gradient(to bottom, #fefab8, #cac466);
	display: flex;
	justify-content: space-between;
	color: #4a3300;
	padding: 22px 190px;
`;
