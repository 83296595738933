import styled from "styled-components";

export const Contaniner = styled.div`
	width: 100vw;
	height: 35px;
	background: linear-gradient(to bottom, #fefab8, #cac466);
	display: flex;
	justify-content: space-around;
	padding: 8px 100px;
	a {
		font-size: 12px;
		font-family: "Open Sans", "Helvetica Neue", sans-serif;
		color: #4a3300;
		font-weight: 100;
	}
	.icon {
		margin: 0px 5px;
		margin-top: 2px;
		font-size: 10px;
	}
	.right-border {
		border-right: 1px solid #4a3608;
		padding-right: 5px;
		margin-right: 5px;
	}
	.right-bottom {
		border-right: 1px solid #4a3608;
		padding-right: 5px;
		margin-right: 5px;
	}
	.right {
		margin-top: 8px;
	}
	.social-logos {
		font-size: 15px;
	}
	.border {
		border-right: 1px sold #4a3608;
	}
`;
