import React from "react";
import { Container } from "./styles";
import avatar from "../../../../../Assets/about-avatar.png";
import Testimonials from "../Testimonials";
const AboutContent = () => {
  return (
    <Container>
      <div className="top">
        <p className="description">
          Cultural values are the core principle and ideals upon which the
          entire community protects and relies for existence and harmonious
          relationships. While there are different perspectives for explaining
          cultural value, clothing is one important and valuable way to do so.
          Taking Ethiopia into consideration, our country is an entity where a
          diversified ethnic group exists with a variety of cultural traits,
          beliefs, languages, and tradition with full respect and tolerance.
        </p>
        <p className="description">
          However, in recent years Ethiopia has been faced with the threat of
          losing its long-standing value, design, and pattern. Predominantly, by
          foreign countries trying to substitute the fabric with an imitation
          and also by lowering the price which does not reflect the real value.
          Unfortunately, the spreading of this imitation has been observed in
          different market places all over the country. It is a concern and a
          threat to major stakeholders including over 30 million small-scale
          weavers, designers, tailors, and retailers that depend on this trade.
          They are not only losing their market share but also losing hope in{" "}
        </p>
      </div>
      <div className="bottom">
        <div className="left">
          <p className="description">
            the traditional sector with the quote of modernization. Due to this
            factor, many African countries have been faced with this obstacle
            and ended up with a dangerous stage of cultural deterioration. As an
            illustration, we can mention South Africa, Mali, Ghana and Nigeria
            etc. Three or four decades ago, these countries were interrelated in
            weaving and providing their own traditional cloth. Through time
            foreign countries started imitating their fabrics, which at last
            ended in the extension of weavers and ended up in the extension of
            their traditional cloth. Nowadays, all these countries are importing
            their own traditional clothes from foreign countries which at one
            time were imitating it.
          </p>
          <p className="description">
            Accordingly, this project is proposed by reflecting the main
            problems seen in the authentic traditional cloth of Ethiopia and its
            crisis in recent years. By introducing a mechanism that is a
            technology-based platform, that proposes a solution to mainly
            preserve the traditional wear of Ethiopia and enhance the weavers
            who are the engines of it all.
          </p>
        </div>
        <div className="right">
          <img src={avatar} alt="" className="avatar" />
        </div>
      </div>
    </Container>
  );
};

export default AboutContent;
