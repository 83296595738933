import { Container } from "./styles";
import React from "react";
import { Slider } from "@mui/material";
const Price = () => {
	const [value, setValue] = React.useState([20, 37]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	function valuetext(value) {
		return `${value}°C`;
	}
	return (
		<Container>
			<h1>Price</h1>

			<Slider
				getAriaLabel={() => "Temperature range"}
				value={value}
				onChange={handleChange}
				sx={{
					color: "#673A04",
					marginTop: "10px",
					height: "7px",
					"& .MuiSlider-thumb": {
						borderRadius: "1px",
						color: "#B18B39",
					},
				}}
			/>
			<p>
				Price {value[0]} - {value[1]}
			</p>
		</Container>
	);
};

export default Price;
