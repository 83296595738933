import Body from "./Components/Body";
import ProductDetailCarousel from "./Components/Carousel/ProductDetail";

const ProductDetail = () => {
  return (
    <>
      <ProductDetailCarousel />
      <Body />
    </>
  );
};
export default ProductDetail;
