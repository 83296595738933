import React from "react";
import { Container } from "./styles";
import background from "../../../../../Assets/App.png";
import { DiAppstore } from "react-icons/di";
import { IoLogoGooglePlaystore } from "react-icons/io5";
const AppPromotion = () => {
	return (
		<Container background={background}>
			<h1 className='header'>Download our App</h1>
			<p className='desc'>
				This text is here as a place holder please do not read it. If you are
				still reading you are wasting your time please stop. So you have nothing
				to do, read away then i dought any one will stop you at this point.{" "}
			</p>
			<div className='buttons'>
				<button className='appstore'>
					<DiAppstore />
					Download App
				</button>
				<button className='playstore'>
					<IoLogoGooglePlaystore />
					Download App
				</button>
			</div>
		</Container>
	);
};

export default AppPromotion;
