import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: 260px;
	border: 1px solid #ffe4aa;
	background-color: #fff;
	padding: 40px 20px;
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}
	input {
		height: 36px;
		width: 100%;
		border: 1px solid #e9e8e8;
		border-radius: 2px;
		margin: 10px 0;
		background-color: #fff8ea;
		padding: 0 10px;
	}
	button {
		background-color: #b88923;
		width: 100%;
		height: 36px;
		color: #fff;
		border: none;
		border-radius: 2px;
		margin-top: 10px;
	}
`;
