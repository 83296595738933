import React from "react";

import { Container } from "./styles";
const Contacts = () => {
	return (
		<Container>
			<div className='inside'>
				<div className='location '>
					<h1 className='header'>Ethiopia</h1>

					<div className='right_border'>
						<p className='address'> +251 980 446 619</p>
						<p className='address'>ethiopia@adey-meselesh.de</p>
						<p className='address'>Bole Subcity Woreda 12</p>
						<p className='address'>House number: New, Addis Ababa</p>
					</div>
				</div>
				<div className='location '>
					<h1 className='header'>Germany</h1>

					<div className='right_border'>
						<p className='address'> +49 160 9150 1935</p>
						<p className='address'> adey@adey-meselesh.de</p>
						<p className='address'> Schellingstrasse 22 80799 München</p>
						<p className='address'>House number: New, Addis Ababa</p>
					</div>
				</div>
				<div className='location '>
					<h1 className='header'>Portugal</h1>

					<div className=''>
						<p className='address'> 123 456 7890</p>
						<p className='address'>portugalportugal@adey-meselesh.de</p>
						<p className='address'>Sucursal EM Portugal Rua Dom Francisco</p>
						<p className='address'>Gomesde Avelar, 7 8670-083 Aljezur</p>
					</div>
				</div>
				<div className='note'>
					<h2 className='head'>Our Branches</h2>
					<p className='description'>
						Our Branches are located in a strategically chosen locations in
						around globe both in Africa and Europe. others will be introduced
						soon after.
					</p>
					<button className='contact'>Contact us</button>
				</div>
			</div>
		</Container>
	);
};

export default Contacts;
