import Body from "./Components/Body";
import HomeCarousel from "./Components/Carousel/home-carousel";

const Home = () => {
  return (
    <>
      <HomeCarousel />
      <Body />
    </>
  );
};
export default Home;
