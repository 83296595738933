import { Container } from "./styles";

const Catagories = () => {
	return (
		<Container>
			<h1>Categories</h1>

			<div className='categories'>
				<p className='gray'>Men</p>
				<p className='black'>( 5 )</p>
			</div>
			<div className='categories'>
				<p className='gray'>Women</p>
				<p className='black'>( 14 )</p>
			</div>
			<div className='categories'>
				<p className='gray'>Shirts</p>
				<p className='black'>( 22 )</p>
			</div>
			<div className='categories'>
				<p className='gray'>Accessories</p>
				<p className='black'>( 11 )</p>
			</div>
			<div className='categories'>
				<p className='gray'>Shoes</p>
				<p className='black'>( 70 )</p>
			</div>
			<div className='categories'>
				<p className='gray'>Bags</p>
				<p className='black'>( 18 )</p>
			</div>
		</Container>
	);
};

export default Catagories;
