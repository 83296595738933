import discount from "../../../../Assets/discount.png";
import AppPromotion from "./App-promo";
import Product from "./Center/Product";
import SimilarProducts from "./Center/SimilarProducts";
import Contacts from "./Contact-section";
import Drawer from "./Drawer/drawer.component";
import Brand from "./Left/Brand";
import Catagories from "./Left/Catagories";
import Login from "./Left/Login";
import Price from "./Left/Price";
import Tags from "./Left/Tags";
import Reviews from "./Reviews";
import CustomAccordion from "./Right/Accordion";
import Comments from "./Right/Comments";
import Customers from "./Right/Customers";
import Social from "./Right/Social";
import Subscribe from "./Right/Subscribe";
import { Container } from "./styles";
const Body = () => {
  return (
    <Container>
      <div className="top">
        <h1 className="title">Product Detail</h1>
        <h4 className="sub-title">
          We must take action to preserve traditional cloth; otherwise, it will
          be lost forever.
        </h4>
      </div>
      <div className="gridded">
        <div className="left">
          <Login />
          <Catagories />
          <Price />
          <Brand />
          {/* <Tags /> */}
          <img src={discount} alt="discount" className="discount" />
        </div>

        <div className="center">
          <Product />
          <Drawer>
            <h1 className="placeHolder">Description comes here</h1>
            <h1 className="placeHolder">Additional info comes here</h1>
            <Reviews />
            <h1 className="placeHolder">Shipping comes here</h1>
          </Drawer>
          <SimilarProducts />
        </div>
        <div className="right">
          <Subscribe />
          <Social />
          {/* <Unknown /> */}
          <CustomAccordion />
          <Comments />
          <Customers />
        </div>
      </div>

      <AppPromotion />
      <Contacts />
    </Container>
  );
};

export default Body;
