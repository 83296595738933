import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;

	padding: 0px 0px;
	margin-top: 40px;
	padding-bottom: 20px;
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}
	.comment {
		.content {
			font-size: 13px;
			color: #848994;
			line-height: 25px;
		}
		border-bottom: 2px solid #b88923;
		margin-bottom: 25px;
	}
	.top {
		display: flex;

		h1 {
			font-size: 14px;
			margin: 0;
			padding: 0;
		}
		p {
			font-size: 9px;
			margin: 0;
			padding: 0;
			margin-left: 10px;
			margin-top: 6px;
			color: #9fa4af;
		}
	}

	.owl-theme .owl-dots .owl-dot.active span,
	.owl-theme .owl-dots .owl-dot:hover span {
		background-color: #b88922 !important;
	}
	.owl-theme .owl-dots .owl-dot span {
		background-color: transparent !important;
	}
`;
