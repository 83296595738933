import React from "react";
import { Routes, Route } from "react-router";
import Home from "./Views/Home/home";
import About from "./Views/About";
import ProductDetail from "./Views/ProductDetail/ProductDetail";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/detail/:id" element={<ProductDetail />} />

      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default AppRoutes;
