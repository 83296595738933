import React, { useState } from "react";
import { Container } from "./drawer.styles";

const Drawer = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(2);
  const steps = [
    "Description",
    "Additional Information",
    "Review (3)",
    "Shipping & Delivery",
  ];

  return (
    <Container>
      <div className="nav">
        {steps.map((step, index) => {
          const isActive = index === currentStep;
          return (
            <div
              className={`tab ${isActive && "active"}`}
              key={index}
              onClick={() => setCurrentStep(index)}
            >
              <p className="tab-name">{step}</p>
            </div>
          );
        })}
      </div>

      <div className="content">
        {React.Children.map(children, (child, index) => {
          if (index === currentStep) {
            return <div key={index}>{child}</div>;
          } else {
            return null;
          }
        })}
      </div>
    </Container>
  );
};

export default Drawer;
