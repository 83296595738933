import styled from "styled-components";
export const Container = styled.div`
	height: fit-content;
	padding: 0px 9vw;
	margin-top: 70px;

	.sub {
		width: 100%;
		text-align: center;
		font-size: 20px;
		color: #c9c9c9;
	}
	.header {
		width: 100%;
		text-align: center;
		font-size: 32px;
		color: #ae7d12;
		span {
			font-size: 32px;
			color: #ae7d12;
			text-decoration: underline;
			text-underline-offset: 20px;
		}
		margin-bottom: 50px;
	}
	.date {
		color: #adadad;
		font-size: 12px;
	}
	.news-headline {
		color: #b88923;
		font-size: 18px;
		margin: 0;
	}
	.news-body {
		color: #848994;
		font-weight: 100;
		font-size: 13px;
	}
	.links {
		display: flex;
		justify-content: space-between;
		font-size: 13px;
	}
	.author {
		font-size: 9px;
	}
`;
