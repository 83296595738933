import { Contaniner } from "./address.styles";

const Address = () => {
	return (
		<Contaniner>
			<p>ADEY MAHETEME</p>

			<p>You are here: Home » </p>
		</Contaniner>
	);
};

export default Address;
