import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 0.2px solid #b88923;
  display: grid;
  grid-template-columns: 23% 1fr;
  margin-top: 50px;
  .nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0 auto;
    z-index: 4;
  }

  .tab {
    font-size: 14px;
    font-weight: 500;
    min-width: 50px;
    text-align: center;
    border: 1px solid #eeeeee;
    text-align: left;
    padding-left: 20px;
    cursor: pointer;
    background-color: #f6f6f6;
  }
  .active {
    background-color: #fff;
  }
  .tab-name {
    color: #8c5a04;
  }

  @media screen and (max-width: 800px) {
    width: 100vw;
    padding: 10px;
    .circle {
      width: 20px;
      height: 20px;
    }

    .inner-circle {
      width: 5px;
      height: 5px;
    }
    .tab {
      font-size: 18px;
      font-weight: 400;
    }
    .tab-name {
      display: none;
    }
    .active {
      display: block;
    }
    .progress {
      top: 10px;
      width: 100%;
    }
  }
`;
