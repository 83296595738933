import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;
	border: 1px solid #ffe4aa;
	background-color: #fff;
	padding: 40px 20px;
	margin-top: 40px;
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}

	.categories {
		border-bottom: 1px solid #acacac;
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin-top: 20px;
		p {
			padding: 0;
			margin: 5px 0;
		}
		.gray {
			color: #acacac;
		}
		.black {
			color: #000;
		}
	}
`;
