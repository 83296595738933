import React from "react";
import { Container } from "./styles";
import ProductImages from "./Product-image/product-image.component";
import ProductDetails from "./Product-detail/Product-detail.component";
const Product = () => {
  return (
    <Container>
      <ProductImages />
      <ProductDetails />
    </Container>
  );
};

export default Product;
