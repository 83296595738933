import styled from "styled-components";

export const Contaniner = styled.div`
  width: 100vw;
  height: fit-content;
  background: linear-gradient(to top, #4a3300, #674c0f);
  display: flex;
  justify-content: space-around;
  padding: 50px 100px;
  padding-bottom: 30px;
  p {
    color: #ce7b00;
    span {
      color: #fefab8;
    }
  }
  .social-logos {
    margin: 0px 5px;
    margin-top: 2px;
    font-size: 50px;
  }
`;
