import Body from "./Components/Body";
import AboutCarousel from "./Components/Carousel/about-carousel";

const About = () => {
  return (
    <>
      <AboutCarousel />
      <Body />
    </>
  );
};
export default About;
