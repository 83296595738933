import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;

	padding: 0px 0px;
	margin-top: 0px;
	padding-bottom: 20px;

	.slider {
		border: 1px solid #ffe4aa;
		background-color: #fff;
		padding: 20px;
		margin-top: 30px;
		position: relative;
	}
	.piramid {
		border-left: 1px solid #ffe4aa;
		border-bottom: 1px solid #ffe4aa;
		position: absolute;
		bottom: -29px;
		background-color: #fff;
		left: calc(50% - 12.5px);
		transform: rotate(-45deg);
		width: 25px;
		height: 25px;
	}
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}
	.comment {
		.content {
			font-size: 13px;
			color: #848994;
			line-height: 25px;
		}
		border-bottom: 2px solid #b88923;
		margin-bottom: 25px;
	}
	.top {
		display: flex;

		h1 {
			font-size: 14px;
			margin: 0;
			padding: 0;
		}
		p {
			font-size: 9px;
			margin: 0;
			padding: 0;
			margin-left: 10px;
			margin-top: 6px;
			color: #9fa4af;
		}
	}
	.name {
		text-align: center;
		width: 100%;
		margin-top: 30px;
		font-size: 13px;
	}
	.sub-title {
		font-size: 13px;
	}
`;
