import styled from "styled-components";

export const Contaniner = styled.div`
	width: 100vw;
	height: 95px;
	background: linear-gradient(to top, #4a3300, #674c0f);
	display: flex;
	justify-content: space-between;
	padding: 5px 250px;
	padding-right: 140px;
	position: relative;
	.separator {
		position: absolute;
		bottom: -18px;
		left: 0;
		width: 100vw;
	}
	.logo {
		width: 110px;
	}
	.logo_container {
		display: flex;
		flex-direction: column;

		span {
			color: #fefab8;
			font-size: 12px;
			width: 200px;
		}
	}
`;

export const LinkContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	height: 100%;
	margin-left: 50px;
	a {
		font-size: 14px !important;
		font-family: "Montserrat", sans-serif;
		color: #fefab8 !important;
		height: fit-content;
		margin-top: 30px;
	}

	.buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0px;
		margin-top: 23px;
	}
	.icon {
		background-color: #fdf9b7;
		border: none;
		height: 33px;
		width: 33px;
		margin-right: 10px;
		font-size: 18px;
	}
`;
