import styled from "styled-components";
export const Container = styled.div`
  height: fit-content;
  padding: 0px;
  margin-top: 150px;
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-gap: 50px;
  .sub {
    color: #ae7d12;
    font-size: 22px;
    line-height: 35px;
    font-weight: 600;
    span {
      color: #673a04;
    }
  }
  .desc {
    color: #848994;
    font-size: 14px;
    line-height: 22px;
    font-weight: 200;
    margin: 30px 0;
  }
  .read-more {
    border-radius: 5px;
    border: none;
    padding: 7px 15px;
    background-color: #b88923;
    color: #fff;
  }
  .right {
    width: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
  }
  .card-left,
  .card-center,
  .card-right {
    width: 198px;
    border-radius: 7px;
    height: 365px;
    background-color: #a26508;
    z-index: 9;
    position: absolute;
    padding: 40px 5px;
  }
  .card-left {
    left: 0px;
  }
  .card-right {
    left: 300px;
  }
  .card-center {
    background-color: #673a04;
    z-index: 999;
    width: 220px;
    left: 140px;
    margin-top: -5.5%;
    height: 430px;
    box-shadow: 1px 0px 21px -1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 21px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 21px -1px rgba(0, 0, 0, 0.75);
  }
  .price {
    color: #fff;
    width: 100%;
    text-align: center;
    margin: 0px;
  }
  .package-name {
    color: #fff;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin: 0px;
  }
  .duration {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: #4a3300;
    margin: 25px 0;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    margin-bottom: 20px;
  }
  ul li {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 11px;
    font-weight: 200;
    font-style: italic;
    span {
      color: #fff;
      text-decoration: underline;
      text-underline-offset: 10px;
    }
  }
  .buy {
    color: #fff;
    width: fit-content;
    text-align: center;
    background-color: #fefab8;
    border: none;
    border-radius: 5px;
    color: #8c5a04;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 20px;
    margin: 15px 0;
    margin-left: 24%;
  }
`;
