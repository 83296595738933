import { Contaniner, LinkContainer } from "./Navbar.styles";
import logo from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import separator from "../../Assets/separator.png";
import { BiSearchAlt2 } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";
import { useState } from "react";
const Navbar = () => {
	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<Contaniner>
			<Link to='/' className='logo_container'>
				<img src={logo} alt='' className='logo' />
				<span>ባህል፣ እሴት፣ ትውፊት</span>
			</Link>
			<LinkContainer>
				<Link
					to='/'
					className={activeTab === 0 ? "active" : ""}
					onClick={() => handleTabClick(0)}
				>
					Home
				</Link>
				<Link
					to='/'
					className={activeTab === 1 ? "active" : ""}
					onClick={() => handleTabClick(1)}
				>
					Women
				</Link>
				<Link to='/'>Men</Link>

				<Link
					to='/'
					className={activeTab === 2 ? "active" : ""}
					onClick={() => handleTabClick(2)}
				>
					Accessories
				</Link>
				<Link
					to='/'
					className={activeTab === 3 ? "active" : ""}
					onClick={() => handleTabClick(3)}
				>
					Shortcodes
				</Link>
				<Link
					to='/'
					className={activeTab === 4 ? "active" : ""}
					onClick={() => handleTabClick(4)}
				>
					Contact
				</Link>
				<div className='buttons'>
					<button className='icon'>
						<BiSearchAlt2 />
					</button>

					<button className='icon'>
						<FaShoppingCart />
					</button>
				</div>
			</LinkContainer>
			<img src={separator} alt='' className='separator' />
		</Contaniner>
	);
};

export default Navbar;
