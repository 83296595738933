import React from "react";
import { Card, Container } from "./styles";
import collection1 from "../../../../../../Assets/collection1.png";
import collection2 from "../../../../../../Assets/collection2.png";
import collection3 from "../../../../../../Assets/collection3.png";
import collection4 from "../../../../../../Assets/collection4.png";
import collection5 from "../../../../../../Assets/collection5.png";
import separator from "../../../../../../Assets/separator.png";
import { Link } from "react-router-dom";
const Collections = () => {
	return (
		<Container>
			<div className='collections'>
				<div className='left'>
					<Card back={collection1}>
						<p className='title'>
							Kids Collection <Link to='#'>Show more</Link>
						</p>
					</Card>
					<Card back={collection2}>
						{" "}
						<p className='title'>
							Men Collection <Link to='#'>Show more</Link>
						</p>
					</Card>
					<Card back={collection3}>
						<p className='title'>
							Footwear Collection <Link to='#'>Show more</Link>
						</p>
					</Card>
					<Card back={collection4}>
						<p className='title'>
							Women Collection <Link to='#'>Show more</Link>
						</p>
					</Card>
				</div>
				<div className='right'>
					<Card back={collection5} fullHeight={true}>
						<p className='title'>
							Women Collection <Link to='#'>Show more</Link>
						</p>
					</Card>
				</div>
			</div>

			<img src={separator} alt='separetor' className='sparator' />
		</Container>
	);
};

export default Collections;
