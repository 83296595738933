import { Link } from "react-router-dom";
import { Contaniner } from "./styles";

import {
	FaCcMastercard,
	FaCcPaypal,
	FaCcVisa,
	FaCcStripe,
} from "react-icons/fa";
const PaymentMethods = () => {
	return (
		<Contaniner>
			<div className='left'></div>
			<div className='center'>
				<p className='copyright'>
					<span>Aday Mahetebe</span> - Copyright 2023. Developed by{" "}
					<span>Adey Meselesh</span>
				</p>
			</div>
			<div className='right'>
				<Link to='#' className='right-bottom'>
					<FaCcVisa className='social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaCcPaypal className='social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaCcMastercard className=' social-logos' />
				</Link>
				<Link to='#' className='right-bottom'>
					<FaCcStripe className=' social-logos' />
				</Link>
			</div>
		</Contaniner>
	);
};

export default PaymentMethods;
