import { Checkbox } from "@mui/material";
import React from "react";
import { Container } from "./styles";
const Brand = () => {
	return (
		<Container>
			<h1>Brand</h1>
			<div className='flex'>
				<Checkbox color='default' label='Aleme' />
				<p>Aleme</p>
			</div>
			<div className='flex'>
				<Checkbox label='Adey' defaultChecked color='default' />
				<p>Adey</p>
			</div>
			<div className='flex'>
				<Checkbox label='Tebebe' defaultChecked color='default' />
				<p>Tebebe</p>
			</div>
			<div className='flex'>
				<Checkbox label='Bole' defaultChecked color='default' />
				<p>Bole</p>
			</div>
			<div className='flex'>
				<Checkbox label='Shero Meda' defaultChecked color='default' />
				<p>Shero Meda</p>
			</div>
		</Container>
	);
};

export default Brand;
