import AboutContent from "./About";
import Contacts from "./Contact-section";
import OurTeam from "./OurTeam";
import CustomAccordion from "./Right/Accordion";
import Comments from "./Right/Comments";
import Customers from "./Right/Customers";
import Social from "./Right/Social";
import Subscribe from "./Right/Subscribe";
import Subscriptions from "./Subscription";
import Testimonials from "./Testimonials";
import { Container } from "./styles";
const Body = () => {
  return (
    <Container>
      <div className="top">
        <h1 className="title">About Us</h1>
        <h4 className="sub-title">
          FIND YOUR AUTISTIC ETHIOPIAN TRADITIONAL CLOTHES
        </h4>
      </div>
      <div className="gridded">
        <div className="center">
          <AboutContent />
          <Testimonials />
          <Subscriptions />
          <OurTeam />
          <Contacts />
          {/* <Subscriptions /> */}
        </div>
        <div className="right">
          <Subscribe />
          <Social />
          {/* <Unknown /> */}
          <CustomAccordion />
          <Comments />
          <Customers />
        </div>
      </div>

      {/* <Subscriptions /> */}
    </Container>
  );
};

export default Body;
