import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 40px;
  .top {
    .title {
      font-size: 40.55px;
      font-weight: 500;
      color: #ae7d12;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      margin-top: 40px;
    }
  }
  .title {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 300;
    color: #ae7d12;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .gridded {
    display: grid;

    max-width: 100%;
    padding-left: 120px;
    grid-template-columns: 1fr 200px;
    grid-gap: 40px;
    margin-top: 40px;
  }
  .discount {
    max-width: 100%;
    height: auto;
    margin-top: 40px;
  }
`;
