import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { ImExit, ImSearch } from "react-icons/im";
import { IoBasketOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import featured1 from "../../../../../../Assets/featured1.png";
import featured2 from "../../../../../../Assets/featured2.png";
import featured3 from "../../../../../../Assets/featured3.png";
import featured4 from "../../../../../../Assets/featured4.png";
import { ButtonContainer, Container, Overlay, Product } from "./styles";

const SimilarProducts = () => {
  return (
    <Container>
      <div className="products">
        <Product>
          <img src={featured1} alt="featured" />
          <div className="price">
            <p className="new">$19.00 </p>
            <p className="old">$26.00</p>
          </div>
          <Overlay>
            <ButtonContainer>
              <div className="right">
                <div className="ham">
                  <RxHamburgerMenu />
                </div>
                <button className="icon">
                  <IoBasketOutline />
                  <p className="desc">ADD TO CART</p>
                </button>
                <button className="icon">
                  <ImExit />
                  <p className="desc">ADD TO COMPARE</p>
                </button>
                <button className="icon">
                  <BsSuitHeartFill />
                  <p className="desc">ADD TO WISHLIST</p>
                </button>
                <button className="icon">
                  <ImSearch />
                  <p className="desc">VIEW DETAIL</p>
                </button>
              </div>
            </ButtonContainer>
          </Overlay>
          <p className="title">PRODUCT TITLE </p>
          <p className="stock">. Instock</p>
        </Product>
        <Product>
          <img src={featured2} alt="featured" />
          <div className="price">
            <p className="new">$19.00 </p>
            <p className="old">$26.00</p>
          </div>
          <Overlay>
            <ButtonContainer>
              <div className="right">
                <div className="ham">
                  <RxHamburgerMenu />
                </div>
                <button className="icon">
                  <IoBasketOutline />
                  <p className="desc">ADD TO CART</p>
                </button>
                <button className="icon">
                  <ImExit />
                  <p className="desc">ADD TO COMPARE</p>
                </button>
                <button className="icon">
                  <BsSuitHeartFill />
                  <p className="desc">ADD TO WISHLIST</p>
                </button>
                <button className="icon">
                  <ImSearch />
                  <p className="desc">VIEW DETAIL</p>
                </button>
              </div>
            </ButtonContainer>
          </Overlay>
          <p className="title">PRODUCT TITLE </p>
          <p className="stock">. Instock</p>
        </Product>
        <Product>
          <img src={featured3} alt="featured" />
          <div className="price">
            <p className="new">$19.00 </p>
            <p className="old">$26.00</p>
          </div>
          <Overlay>
            <ButtonContainer>
              <div className="right">
                <div className="ham">
                  <RxHamburgerMenu />
                </div>
                <button className="icon">
                  <IoBasketOutline />
                  <p className="desc">ADD TO CART</p>
                </button>
                <button className="icon">
                  <ImExit />
                  <p className="desc">ADD TO COMPARE</p>
                </button>
                <button className="icon">
                  <BsSuitHeartFill />
                  <p className="desc">ADD TO WISHLIST</p>
                </button>
                <button className="icon">
                  <ImSearch />
                  <p className="desc">VIEW DETAIL</p>
                </button>
              </div>
            </ButtonContainer>
          </Overlay>
          <p className="title">PRODUCT TITLE </p>
          <p className="stock">. Instock</p>
        </Product>
        <Product>
          <img src={featured4} alt="featured" />
          <div className="price">
            <p className="new">$19.00 </p>
            <p className="old">$26.00</p>
          </div>
          <Overlay>
            <ButtonContainer>
              <div className="right">
                <div className="ham">
                  <RxHamburgerMenu />
                </div>
                <button className="icon">
                  <IoBasketOutline />
                  <p className="desc">ADD TO CART</p>
                </button>
                <button className="icon">
                  <ImExit />
                  <p className="desc">ADD TO COMPARE</p>
                </button>
                <button className="icon">
                  <BsSuitHeartFill />
                  <p className="desc">ADD TO WISHLIST</p>
                </button>
                <button className="icon">
                  <ImSearch />
                  <p className="desc">VIEW DETAIL</p>
                </button>
              </div>
            </ButtonContainer>
          </Overlay>
          <p className="title">PRODUCT TITLE </p>
          <p className="stock">. Instock</p>
        </Product>
      </div>
    </Container>
  );
};

export default SimilarProducts;
