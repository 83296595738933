import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;

	padding: 0px 0px;
	margin-top: 60px;
	padding-bottom: 20px;
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}
	svg {
		color: #b18b39 !important;
		fill: #b18b39 !important;
		font-size: 18px;
	}

	.card {
		border: 1px solid #ffe4aa;
		background-color: #fff;
	}

	button {
		background-color: #f8f7f7;
		padding: 7px 10px;
		border: none;
		border-radius: 3px;
		margin: 3px;
		border: 1px solid #e9e8e8;
		cursor: pointer;
	}
`;
