import styled from "styled-components";
export const Container = styled.div`
	height: fit-content;
	padding: 100px 0vw;
	padding-left: 50%;
	padding-right: 10vw;
	margin-top: 0px;
	background: url(${(props) => props.background}) center;
	height: 420px;
	width: 100vw;
	margin-left: -40px;
	background-repeat: no-repeat;
	.header {
		color: #fefab8;
		font-size: 40px;
		width: 100%;
		font-weight: 500;
		text-align: center;
	}
	p {
		color: #fff;
		font-size: 17px;
		font-weight: 200;
	}
	.buttons {
		width: 60%;
		margin: 35px auto;
		display: flex;
		justify-content: space-around;
	}
	.appstore,
	.playstore {
		border: none;
		padding: 10px 20px;
		border-radius: 5px;
	}
	.appstore {
		background-color: #b88923;
	}
`;
