import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Address from "./Components/Address/address.component";
import Footer from "./Components/Footer";
import InfoBar from "./Components/InfoBar/infobar.component";
import Navbar from "./Components/Navbar/Navbar.component";
import PaymentMethods from "./Components/Payment-methods";
import AppRoutes from "./routes";

function App() {
  return (
    <Router>
      <InfoBar />
      <Navbar />
      <Address />
      <AppRoutes />
      <Footer />
      <PaymentMethods />
    </Router>
  );
}

export default App;
