import styled from "styled-components";
export const Container = styled.div`
	width: 100vw;
	height: 600px;
	position: relative;
	.separator {
		max-width: 100%;
		margin: 0;
		padding: 0;
		height: 20px;
		object-fit: cover;
	}
	.owl-theme {
		padding: 0 !important;
	}
	.carousel-item {
		height: 580px;
		width: 100vw;
		background-size: cover;
		background-position: left center;

		display: grid;
		grid-template-columns: 2fr 2fr;
		padding-top: 100px;
		padding-right: 120px;
		padding-left: 120px;
		margin: 0 !important;
		p {
			font-size: 16px;
			color: #fefab8;
			font-weight: 100;
			font-family: Montserrat;
			text-align: center;
			line-height: 25px;
			padding-top: 30px;
		}
	}
	.qoute {
		max-width: 150px;
		object-fit: cover;
		margin: 0 auto;
	}

	.owl-dots {
		position: absolute;
		bottom: 20px;
		width: 100%;
	}
	.owl-dot span {
		background-color: #a39864 !important;
	}
	.active span {
		background-color: #fefab8 !important;
	}
	.owl-nav {
		position: absolute;
		top: 45%;
		padding: 0 50px;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.owl-nav span {
		font-size: 100px !important;
		color: #b18b39 !important;
	}
	.owl-nav:hover span {
		background-color: transparent !important;
	}
	.links {
		padding-left: 200px;
		margin-top: 50px;
		color: #a98436;

		a {
			color: #a98436;
			text-decoration: underline;
			margin: 0 10px;
			.icon {
				margin: 0 !important;
				font-size: 10px;
				margin-left: 3px;
				margin-bottom: 0;
			}
		}
		.icon {
			margin: 0 !important;
		}
	}
`;
