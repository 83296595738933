import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Container } from "./styles";
import qoute from "../../../../../Assets/testtimonial-qoute.png";
import avatar from "../../../../../Assets/Daniel.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

const OurTeam = () => {
  const options = {
    items: 3,
    margin: 10,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: false,
    arrows: true,
    // margin: 0,
  };

  const items = [
    <div key={1} className="carousel-item">
      <div className="content">
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Daniel Fiseha</p>
        <p className="title">CEO-Founder</p>
        <Link to="">@gmail.com</Link>
        <Link to=""> +251 0000000</Link>
      </div>
    </div>,
    <div key={2} className="carousel-item">
      <div className="content">
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Samrawite Abebe</p>
        <p className="title">Lawyers</p>
        <Link to="">@gmail.com</Link>
        <Link to=""> +251 0000000</Link>
      </div>
    </div>,
    <div key={3} className="carousel-item">
      <div className="content">
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Samiel Negash</p>
        <p className="title">Project Manager </p>
        <Link to="">@gmail.com</Link>
        <Link to=""> +251 0000000</Link>
      </div>
    </div>,
    <div key={4} className="carousel-item">
      <div className="content">
        <img src={avatar} alt="" className="avatar" />
        <p className="name">Daniel Fiseha</p>
        <p className="title">CEO-Founder</p>
        <Link to="">@gmail.com</Link>
        <Link to=""> +251 0000000</Link>
      </div>
    </div>,
  ];

  return (
    <Container>
      <h1 className="header">OUR TEAM</h1>

      <div className="slider">
        <OwlCarousel {...options} className="owl-theme ">
          {items.map((item) => (
            <div key={item.key}>{item}</div>
          ))}
        </OwlCarousel>
        <p className="piramid"></p>
      </div>
    </Container>
  );
};

export default OurTeam;
