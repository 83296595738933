import discount from "../../../../Assets/discount.png";
import AppPromotion from "./App-promo";
import Collections from "./Center/Collections";
import Featured from "./Center/Featured";
import Contacts from "./Contact-section";
import Discounts from "./Discounts";
import Brand from "./Left/Brand";
import Catagories from "./Left/Catagories";
import Login from "./Left/Login";
import Price from "./Left/Price";
import Tags from "./Left/Tags";
import News from "./News";
import CustomAccordion from "./Right/Accordion";
import Comments from "./Right/Comments";
import Customers from "./Right/Customers";
import Social from "./Right/Social";
import Subscribe from "./Right/Subscribe";
import Subscriptions from "./Subscription";
import { Container } from "./styles";
const Body = () => {
	return (
		<Container>
			<div className='top'>
				<h1 className='title'>WELCOME TO ADEY MAHETEME</h1>
				<h4 className='sub-title'>
					FIND YOUR AUTISTIC ETHIOPIAN TRADITIONAL CLOTHES
				</h4>
			</div>
			<div className='gridded'>
				<div className='left'>
					<Login />
					<Catagories />
					<Price />
					<Brand />
					<Tags />
					<img src={discount} alt='discount' className='discount' />
				</div>

				<div className='center'>
					<Collections />
					<Featured />
				</div>
				<div className='right'>
					<Subscribe />
					<Social />
					{/* <Unknown /> */}
					<CustomAccordion />
					<Comments />
					<Customers />
				</div>
			</div>
			<Discounts />
			<News />
			<Subscriptions />
			<AppPromotion />
			<Contacts />
		</Container>
	);
};

export default Body;
