import React from "react";
import { Container } from "./styles";
import banner from "../../../../../Assets/discount-banner.png";
const Discounts = () => {
	return (
		<Container background={banner}>
			<h1 className='header'>CLOTHE COLLECTIONS</h1>
			<p className='product-title'>Product Title Here</p>
			<p className='discount'>
				Up To <span>40% </span> Discount
			</p>
			<p className='description'>
				This text is here as a place holder please do not read it. If you are
				still reading you are wasting your time please stop. So you have nothing
				to do, read away then i dought any one will stop you at this point.{" "}
			</p>
			<div className='count-down'>
				<p className='box'>
					02 <br /> Days
				</p>
				<p className='box'>
					10
					<br /> Hours
				</p>
				<p className='box'>
					15 <br />
					Minutes
				</p>
				<p className='box'>
					54
					<br /> Seconds
				</p>
				<button className='shop'>SHOP THIS NOW</button>
			</div>
		</Container>
	);
};

export default Discounts;
