import { Container, Review } from "./styles";
import reviewer1 from "../../../../../Assets/reviewer1.png";
import reviewer2 from "../../../../../Assets/reviewer2.png";
import reviewer3 from "../../../../../Assets/reviewer3.png";
const Reviews = () => {
  return (
    <Container>
      <div className="left">
        <p className="lable">3 REVIEWS</p>

        <Review>
          <img src={reviewer1} alt="" />
          <div className="content">
            <div className="top">
              <p className="name">Abebe Teka </p>
              <p className="date">January 06, 2023</p>
            </div>
            <p className="review">
              This text is here as a place holder please do not read it. If you
              are still reading you are wasting your time please stop. So you
              have nothing to do,
            </p>
          </div>
        </Review>
        <Review>
          <img src={reviewer2} alt="" />
          <div className="content">
            <div className="top">
              <p className="name">Abebe Teka </p>
              <p className="date">January 06, 2023</p>
            </div>
            <p className="review">
              This text is here as a place holder please do not read it. If you
              are still reading you are wasting your time please stop. So you
              have nothing to do,
            </p>
          </div>
        </Review>
        <Review>
          <img src={reviewer3} alt="" />
          <div className="content">
            <div className="top">
              <p className="name">Abebe Teka </p>
              <p className="date">January 06, 2023</p>
            </div>
            <p className="review">
              This text is here as a place holder please do not read it. If you
              are still reading you are wasting your time please stop. So you
              have nothing to do,
            </p>
          </div>
        </Review>
      </div>
      <div className="right">
        <p className="title">HERE YOU CAN REVIEW THIS ITEM.</p>

        <div className="input-field">
          <p className="lebel">Your Name</p>
          <input type="text" className="input" />
        </div>
        <div className="input-field">
          <p className="lebel">Your Name</p>
          <input type="text" className="input" />
        </div>
        <div className="input-field">
          <p className="lebel">Your Name</p>
          <textarea type="text" className="textarea" />
        </div>
        <div className="flex">
          <button className="outline">ADD REVIEW</button>
          <button className="fill">ADD REVIEW</button>
        </div>
      </div>
    </Container>
  );
};

export default Reviews;
