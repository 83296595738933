import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;
	/* border: 1px solid #ffe4aa;
	background-color: #fff; */
	padding: 0px 0px;
	margin-top: 40px;
	h1 {
		font-size: 16px;
		color: #b88923;
		margin: 0;
		margin-bottom: 10px;
	}
	h1:first-letter {
		text-decoration: underline;
	}
	.holder {
		border: 1px solid #ffe4aa;
		background-color: #fff;
	}
	.margin {
		margin-bottom: 7px;
	}
	.sub {
		font-size: 13px !important;
		color: #b88923 !important;
	}
	.header {
		border-bottom: 1px solid #ffe4aa;
	}
	svg {
		color: #b88923 !important;
		fill: #b88923 !important;
	}
	p {
		text-align: left;
		line-height: 20px;
		font-size: 13px;
	}
`;
