import React, { useState } from "react";
import { ButtonContainer, Container, Overlay, Product } from "./styles";
import featured1 from "../../../../../../Assets/featured1.png";
import featured2 from "../../../../../../Assets/featured2.png";
import featured3 from "../../../../../../Assets/featured3.png";
import featured4 from "../../../../../../Assets/featured4.png";
import featured5 from "../../../../../../Assets/featured5.png";
import featured6 from "../../../../../../Assets/featured6.png";
import featured7 from "../../../../../../Assets/featured7.png";
import featured8 from "../../../../../../Assets/featured8.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoBasketOutline } from "react-icons/io5";
import { ImExit, ImSearch } from "react-icons/im";
import { BsSuitHeartFill } from "react-icons/bs";

const Featured = () => {
	const [filter, setFilter] = useState(0);
	return (
		<Container>
			<div className='top'>
				<p className='header'>
					Featured Products
					<span>MAKE YOUR STYLE COOL</span>
				</p>
				<ul className='filters'>
					<li
						className={`${filter === 0 && "active"}`}
						onClick={() => setFilter(0)}
					>
						NEW ARRIVAL
					</li>
					<li
						className={`${filter === 1 && "active"}`}
						onClick={() => setFilter(1)}
					>
						{" "}
						BEST SELLER{" "}
					</li>
					<li
						className={`${filter === 2 && "active"}`}
						onClick={() => setFilter(2)}
					>
						{" "}
						POPULAR{" "}
					</li>
					<li
						className={`${filter === 3 && "active"}`}
						onClick={() => setFilter(3)}
					>
						{" "}
						SPECIAL OFFER{" "}
					</li>
					<li
						className={`${filter === 4 && "active"}`}
						onClick={() => setFilter(4)}
					>
						{" "}
						LIMITED EDITION
					</li>
				</ul>
			</div>

			<div className='products'>
				<Product>
					<img src={featured1} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured2} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured3} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured4} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>

				<Product>
					<img src={featured5} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured6} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured7} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured8} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured6} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured7} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured8} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
				<Product>
					<img src={featured8} alt='featured' />
					<div className='price'>
						<p className='new'>$19.00 </p>
						<p className='old'>$26.00</p>
					</div>
					<Overlay>
						<ButtonContainer>
							<div className='right'>
								<div className='ham'>
									<RxHamburgerMenu />
								</div>
								<button className='icon'>
									<IoBasketOutline />
									<p className='desc'>ADD TO CART</p>
								</button>
								<button className='icon'>
									<ImExit />
									<p className='desc'>ADD TO COMPARE</p>
								</button>
								<button className='icon'>
									<BsSuitHeartFill />
									<p className='desc'>ADD TO WISHLIST</p>
								</button>
								<button className='icon'>
									<ImSearch />
									<p className='desc'>VIEW DETAIL</p>
								</button>
							</div>
						</ButtonContainer>
					</Overlay>
					<p className='title'>PRODUCT TITLE </p>
					<p className='stock'>. Instock</p>
				</Product>
			</div>
		</Container>
	);
};

export default Featured;
