import { Link } from "react-router-dom";
import { Container } from "./Product-detail.styles";

import { BsFillStarFill, BsStarHalf } from "react-icons/bs";
const ProductDetails = () => {
  return (
    <Container>
      <h1 className="product-title border">Product Title Here</h1>
      <div className="border">
        <p className="description">
          This text is here as a place holder please do not read it. If you are
          still reading you are wasting your time please stop. So you have
          nothing to do, read away then i dought any one will stop you at this
          point.{" "}
        </p>
        <p className="description">
          Product Dimensions: 34.75" H x 33.25" W x 33" D Product Weight: 33lbs
        </p>
      </div>

      <div className="reviews border">
        <div className="stars">
          <BsFillStarFill />
          <BsFillStarFill />
          <BsFillStarFill />
          <BsFillStarFill />
          <BsStarHalf />
        </div>
        <div className="count">3 reviews</div>
        <Link to="#"> Leave a review</Link>
      </div>
      <div className="color-size border">
        <div className="colors">
          <button className="black">.</button>
          <button className="gray">.</button>
          <button className="green">.</button>
          <button className="cream">.</button>
          <button className="brown">.</button>
        </div>
        <p className="size">Size : 108" x 43" x 24"</p>
      </div>
      <div className="price-container border">
        <p className="price">$ 1000.00 </p>
        <input type="number" min={1} defaultValue={1} className="quantity" />
        <button className="add-to-cart">ADD TO CART</button>
      </div>
      <div className="more ">
        <button className="wish">Add to Wishlist</button>
        <button className="compare">Compare</button>
        <p className="prop">
          SKU : <span>4625 </span>
        </p>
        <p className="prop">
          Availablity: <span>In stock </span>
        </p>
        <p className="prop">
          Need help : <span>0122 354 223 </span>
        </p>
        <p className="prop">
          Order by phone : <span>0122 354 223 </span>
        </p>
        <p className="prop">Free shipping & Free return</p>
      </div>
    </Container>
  );
};

export default ProductDetails;
