import styled from "styled-components";
export const Container = styled.div`
	width: 100%;
	height: fit-content;
	padding: 0px 0px;
	margin-top: 0px;
	.top {
		display: flex;
		justify-content: space-between;
		.header {
			display: flex;
			flex-direction: column;
			font-size: 20px;
			margin-top: 60px;
			color: #ae7d12;
			text-align: left;
			span {
				font-size: 12px;
				margin-top: 5px;
			}
		}
		.filters {
			display: flex;
			width: fit-content;
			margin-top: 70px;

			li {
				font-size: 12px;
				width: fit-content;
				clear: both;
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				margin: 0 10px;
				cursor: pointer;
			}
			.active {
				color: #ae7d12;
			}
		}
	}
	.products {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 20px;
		max-width: 100%;
		width: 100%;
		margin-top: 30px;
	}
`;

export const Product = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px;
	position: relative;
	transition: opacity 0.3s ease-in-out;
	img {
		max-width: 100%;
		width: 100%;
		margin: 0 auto;
		object-fit: cover;
		height: 280px;
	}
	.title {
		font-size: 15px;
		margin: 0;
		color: #673a04;
	}
	.price {
		display: flex;
		text-align: center;

		width: fit-content;
		margin: 0 auto;
		font-size: 13px;
		.new {
			color: #b88923;
			margin-right: 5px;
		}
		.old {
			text-decoration: line-through;
			color: #ababab;
		}
	}
	.stock {
		width: fit-content;
		margin: 10px auto;
		background-color: #edece3;
		border-radius: 20px;
		padding: 10px 20px;
		font-size: 13px;
		color: #b88923;
	}
`;

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0 !important;
	width: 100%;
	height: 280px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(254, 250, 184, 0.7);

	${Product}:hover & {
		opacity: 1;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;

	padding: 0 !important;
	.right {
		background-color: #b88923;
		height: 220px;
		width: 40px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 50px;

		margin-left: 130px !important;
		.ham {
			height: 50px;
			width: 100%;
			border-radius: 50px 50px 0px 0px;
			background-color: #673a04;
			padding: 20px 12px;
			color: #fff;
		}
		.icon {
			height: 100%;
			font-size: 18px;
			background-color: transparent;
			border: none;
			color: #4a3608;
			position: relative;
			cursor: pointer;
			.desc {
				position: absolute;

				top: 0;
				left: -140px;
				font-size: 12px;
				font-weight: 700;
				text-align: right !important;
				width: 130px;
				color: #555555;
			}
			&:hover {
				color: #fffabf;
			}
			&:hover .desc {
				color: #b88923;
			}
		}
	}
`;
