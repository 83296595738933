import { Container } from "./product-image.styles";
import { useState } from "react";
import banner from "../../../../../../../Assets/detail-hero.png";
import banner2 from "../../../../../../../Assets/detail-2.png";
import banner1 from "../../../../../../../Assets/detail-1.png";
const ProductImages = () => {
  const [selected, setSelected] = useState(banner);

  return (
    <Container>
      <img src={selected} alt="" className="banner" />
      <div className="images">
        <img
          src={banner}
          onClick={() => setSelected(banner)}
          alt=""
          className="banner"
        />
        <img
          src={banner1}
          onClick={() => setSelected(banner1)}
          alt=""
          className="banner"
        />
        <img
          src={banner2}
          alt=""
          onClick={() => setSelected(banner2)}
          className="banner"
        />
        <img
          src={banner}
          onClick={() => setSelected(banner)}
          alt=""
          className="banner"
        />
        <img
          src={banner1}
          onClick={() => setSelected(banner1)}
          alt=""
          className="banner"
        />
        <img
          src={banner2}
          alt=""
          onClick={() => setSelected(banner2)}
          className="banner"
        />
      </div>
    </Container>
  );
};

export default ProductImages;
