import styled from "styled-components";

export const Container = styled.div`
  width: 100% !important;
  margin: 0;
  padding: 0;
  .product-title {
    margin: 0;
    padding: 0;
    font-size: 33px;
    font-weight: 500;
    color: #8c5a04;
  }
  .description {
    font-size: 13px;
    font-weight: 200;

    color: #666666;
  }
  .reviews {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    .stars {
      svg {
        fill: #8c5a04;
      }
    }
  }
  .color-size {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    .size {
      margin: 0;
      padding: 0;
    }
    .colors {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 10px;

      button {
        width: 25px;
        height: 25px;
        border: 1px solid #b88923;
      }
      .black {
        background-color: #303030;
        color: #303030;
      }
      .gray {
        background-color: #e0e0e0;
        color: #e0e0e0;
      }
      .green {
        background-color: #aacace;
        color: #aacace;
      }
      .cream {
        background-color: #d4af75;
        color: #d4af75;
      }
      .brown {
        background-color: #4d3c32;
        color: #4d3c32;
      }
    }
  }
  .price-container {
    display: flex;
    padding: 15px 0;
    .price {
      border: 1px solid #b88923;
      padding: 7px;
      height: 35px;
      margin: 0;
    }
    .quantity {
      border: 1px solid #b88923;
      height: 35px;
      width: 70px;
      padding: 7px;
      margin: 0 10px;
    }
    .add-to-cart {
      background-color: #a26508;
      padding: 7px 15px;
      border: none;
      color: #fffbbf;
    }
  }
  .more {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }
  .wish,
  .compare {
    border: none;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    margin: 5px 0;
    padding: 0;
    color: #666666;
    &:hover {
      color: #8c5a04;
      font-weight: 700;
    }
  }
  .prop {
    width: fit-content;
    font-size: 13px;
    height: fit-content;
    margin: 5px 0;
    padding: 0;
    color: #666666;
    span {
      color: #999999;
    }
  }
  .border {
    border-bottom: 1px solid #8c5a04;
    padding: 15px 0;
  }
`;
