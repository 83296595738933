import React from "react";
import { Container } from "./styles";
import { HiOutlineRss } from "react-icons/hi";
import { GrFacebookOption } from "react-icons/gr";
import {
	FaGooglePlusG,
	FaLinkedinIn,
	FaPinterestP,
	FaTwitter,
	FaVimeoSquare,
	FaYoutube,
} from "react-icons/fa";
const Social = () => {
	return (
		<Container>
			<h1>WE ARE SOCIAL</h1>
			<div className='grid'>
				<button className='card'>
					<HiOutlineRss />
				</button>
				<button className='card'>
					<FaGooglePlusG />
				</button>
				<button className='card'>
					<FaLinkedinIn />
				</button>
				<button className='card'>
					<FaPinterestP />
				</button>
				<button className='card'>
					<FaTwitter />
				</button>
				<button className='card'>
					<FaVimeoSquare />
				</button>
				<button className='card'>
					<FaYoutube />
				</button>
				<button className='card'>
					<GrFacebookOption />
				</button>
			</div>
		</Container>
	);
};

export default Social;
